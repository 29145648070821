.job-title{
    color: #C9D4F3;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.job-company{
    color: #FCD751;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.job-date{
    color: rgba(201, 212, 243, 0.65);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.job-descr{
    color: rgba(201, 212, 243, 0.65);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
}

.job-tech{
    color: #C9D4F3;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.job-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
}