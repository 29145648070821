.cards-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem; 
  box-sizing: border-box; 
  overflow-y: scroll; 
  max-height: calc(100vh - 350px);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 1, 1, 0.65);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #FCD751;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #FCD790;
}

.fileicon{
  width: 35px;
  height: 28px;
  flex-shrink: 0;
}
  